import {
  Container,
  Typography,
  Box,
  Link,
  Button,
  Hidden,
} from "@mui/material";
import * as React from "react";

type Record = {
  address: string;
  content: string;
  timestamp: number;
  year: string;
};

interface Props {
  records: Record[];
}

function timeConverter(UNIX_timestamp: number): string {
  var a = new Date(UNIX_timestamp);
  // var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = a.getMonth() + 1;
  var date = a.getDate();
  var hour = a.getHours() < 10 ? "0" + a.getHours() : a.getHours();
  var min = a.getMinutes() < 10 ? "0" + a.getMinutes() : a.getMinutes();
  var sec = a.getSeconds() < 10 ? "0" + a.getSeconds() : a.getSeconds();
  var time = `${year}/${month}/${date} ${hour}:${min}:${sec}`;
  return time;
}

export default function ReadRecord({ records }: Props): any {
  return (
    <>
      {records.length > 0 && (
        <>
          <Typography
            fontSize={{ xs: "24px", md: "30px" }}
            mt={{ md: "100px" }}
            display={{ xs: "none", md: "block" }}
            fontWeight={800}
            gutterBottom
          // color="#1383E2"
          >
            链上览痕
          </Typography>
          <Box
            sx={{
              ml: { xs: "8px", md: "10px" },
              overflow: "scroll",
              mt: { xs: "30px", md: "0px" },
            }}
            height={{ md: "300px" }}
          >
            {records.map((visitRecord) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Box sx={{ display: "flex", gap: 1, mb: -0.5 }}>
                  <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                    {timeConverter(visitRecord.timestamp)} {visitRecord.year}
                    {"版 "}
                    {visitRecord.content}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                    链上地址:{" "}
                    <Link
                      underline="none"
                      target="_blank"
                      href={
                        "https://explorer.nebula.fan/address/" +
                        visitRecord.address
                      }
                    >
                      {visitRecord.address}
                    </Link>
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
}
