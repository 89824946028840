import { Container, Typography, Box, Link } from "@mui/material";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import { json } from "fetchkit";
import Layout from "../layout/Layout";
import ReadRecord from "../components/ReadRecord";

export default function IndexPage({data}:any) {
  const year=2021
  const [record, setRecord] = React.useState([]);
  React.useEffect(() => {
    const getRecord = async () => {
      try {
        const getResponse = await fetch(`/api/get-visit-records`, json({}));
        const getData = await getResponse.json();
        if (getData.message) {
          throw Error(getData.message);
        }
        setRecord(getData);
      } catch (e) {
        alert((e as Error).message);
      }
    };
    getRecord();
  }, []);
  return (
    <Layout backPath="/" title={year + "中国NFT产业白皮书"}>
      <Helmet>
        <title>{year + "中国NFT产业白皮书"}</title>
      </Helmet>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            alignItems: { xs: "center", md: "start" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box width={{ xs: "80vw", md: "600px" }} mr={{ sm: 0, md: 10 }}>
            <Box
              width={{ xs: "80vw", md: "600px" }}
              component="img"
              src="https://cdn.joy-art.cn/feishu/whitebook/img/2021%E4%B8%AD%E5%9B%BDNFT%E4%BA%A7%E4%B8%9A%E7%99%BD%E7%9A%AE%E4%B9%A6.gif"
            />

            <Typography
              fontSize={{ xs: "14px", md: "18px" }}
              align="center"
              color="text.secondary"
              paragraph
            >
              是一份关于中国数字藏品产业现状及未来发展趋势的权威性报告。该白皮书由行业权威机构和专业学者共同撰写，以全面深入的调研和分析为基础，从行业规模、市场需求、技术应用、政策环境等多个方面全面展示了中国数字藏品产业的现状和发展趋势。该白皮书旨在为数字藏品行业的从业者、投资者、政策制定者等各方提供参考和指导，促进数字藏品行业的健康有序发展。
            </Typography>
          </Box>
          <Box width={{ xs: "90vw", md: "100%" }}>
            <Typography
              fontSize={{ xs: "24px", md: "38px", fontWeight: 800 }}
              // color="#1383E2"
            >
              目录
            </Typography>
            <Box sx={{ ml: { xs: "8px", md: "20px" } }}>
              {data.allMdx.nodes
                .filter((val: any) => {
                  return val.frontmatter.year == year;
                })
                .map(({ id, frontmatter }: any) => (
                  <Typography
                    fontSize={{ xs: "18px", md: "22px" }}
                    gutterBottom
                    noWrap
                    color="text.primary"
                  >
                    <Link
                      underline="none"
                      href={`/${frontmatter!.order}/`}
                      color="#1383E2"
                      // color="text.primary"
                    >
                      {frontmatter!.title}
                    </Link>
                  </Typography>
                ))}
            </Box>
            <ReadRecord records={record}></ReadRecord>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: frontmatter___order, order: ASC }) {
      nodes {
        id
        frontmatter {
          order
          title
          year
        }
      }
    }
  }
`;
